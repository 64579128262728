<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>
      <!-- <a-button
        type="primary"
        class="back-btn"
        @click="$router.push({ name: 'senators' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        {{ $t("back") }}
      </a-button> -->
      <a-row class="post-search" type="flex">
        <a-button
          type="primary"
          class="edit-btn"
          v-for="item in typeList"
          :key="item.value"
          @click="onFilter(item)"
        >
          {{ item.name && item.name[$i18n.locale] }}
        </a-button>
        <a-select
          v-model="perPage"
          style="margin-right: 10px"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>
        <!-- <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button> -->
        <a-button
          type="primary"
          @click="$router.push({ name: 'staffs-create' })"
        >
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
      <a-row type="flex" style="width: 100%; justify-content: flex-end">
        <div style="position: relative; width: 300px;  margin-right: 10px">
          <a-input
            v-model="search"
            placeholder="Поиск сотрудника..."
            @input="SearchInput"
            style=""
          />
          <span class="search-error">{{ searchError }}</span>
        </div>
        <a-button
          :disabled="cardsIsEmpty"
          type="primary"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          {{ $t("Delete") }}
        </a-button>
      </a-row>
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :loading="loading"
      :data-source="tableProps.results"
      :pagination="false"
    >
      <div
        slot="news_title"
        slot-scope="item"
        class="td-post-item"
        @click="
          $router.push({
            name: 'staffs-detail',
            params: { id: item.id },
          })
        "
      >
        <b style="cursor: pointer">{{ item.name }}</b>
      </div>

      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>

      <template slot="type" slot-scope="item">
        {{ item.type == 1 ? "Senator" : "Devon" }}
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.status ? 'geekblue' : 'volcano'">
          {{ item.status ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          v-if="item.type == 1"
          class="edit-btn"
          @click="
            $router.push({
              name: 'article',
              params: { senator: item.id },
            })
          "
        >
          {{ $t('Articles') }}
        </a-button>
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({
              name: 'staffs-detail',
              params: { id: item.id },
            })
          "
        >
          {{ $t("Edit") }}
          <a-icon class="action-btns" type="edit" />
        </a-button>
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :current="page"
        :default-page-size="perPage"
        :total="+tableProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          // title: this.$t("TableHead"),
          // width: "400px",
          key: "id",
          scopedSlots: { customRender: "id" },
          fixed: "left",
        },
        {
          title: this.$t("TableHead"),
          width: 350,
          key: "news_title",
          scopedSlots: { customRender: "news_title" },
          fixed: "left",
        },
        {
          title: "Xodim",
          key: "type",
          width: 100,
          scopedSlots: { customRender: "type" },
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        },
        {
          title: this.$t("UsersColTitle2"),
          key: "operation",
          fixed: "right",
          align: "right",
          width: "300px",
          scopedSlots: { customRender: "operation" },
        },
      ],
      search: "",
      tableProps: {
        results: [],
        count: 1,
      },
      title: {
        uz: "Senatorlar",
        oz: "Сенаторлар",
        ru: "Сенаторы",
        en: "Senators",
      },
      LimitList: [
        {
          value: 10,
          title: "10",
        },
        {
          value: 20,
          title: "20",
        },
        {
          value: 40,
          title: "40",
        },
      ],
      typeList: [
        {
          value: 1,
          name: {
            uz: "Senatorlar",
            oz: "Сенаторлар",
            ru: "Сенаторы",
            en: "Senators",
          },
        },
        {
          value: 2,
          name: {
            uz: "Devon xodimlari",
            oz: "Девон ходимлари",
            ru: "Аппарат Сената",
            en: "Senate Apparatus",
          },
        },
      ],
      type: null,
      perPage: 10,
      page: 1,
      cardsIsEmpty: true,
      offset: 0,
      searchError: "",
    };
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item == +itemId);
      if (f != -1) {
        this.selectedPosts.splice(f, 1);
        this.cardsIsEmpty = false;
      } else {
        this.selectedPosts.push(itemId);
        this.cardsIsEmpty = false;
      }

      if (this.selectedPosts.length == 0) {
        this.cardsIsEmpty = true;
      }
      console.log(this.selectedPosts);
    },
    tableperPage() {
      this.offset = 0;
      this.fetchData();
    },
    async Allnews() {
      this.loading = true;
      this.perPage = null;
      this.categoryProps = {
        results: [],
        count: 0,
      };
      try {
        const r = await this.$store.dispatch("control/fetch", {
          // type: 0,
        });
        if (r) {
          this.$set(this, "tableProps", {
            results: r || [],
          });
        }
      } catch (e) {
        this.$sentry.captureMessage(e);
      }
      this.loading = false;
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } });
    },
    SearchInput() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.searchError = "";
        this.offset = 0;
        this.fetchData();
      } else {
        this.searchError = "Kamida 3ta harf yozing";
      }
    },
    onFilter(item) {
      this.offset = 0;
      this.type = item.value;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      this.offset = +this.$route.query.page || 1;
      this.page = this.offset;
      this.offset = (this.offset - 1) * this.perPage;
      try {
        const data = await this.$store.dispatch("control/fetch", {
          limit: this.perPage,
          offset: this.offset,
          name: this.search || null,
          type: this.type,
        });
        if (data && data.status) {
          this.$set(this, "tableProps", {
            results: data.data.results || [],
            count: data.data.count,
          });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }

      this.loading = false;
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$store.dispatch(
              "control/removeItemAction",
              i
            );
            console.log(res);
            if (res && res.status) {
              this.$message.success("Новости успешно удаленно");
            }
            this.fetchData();
          } catch (e) {
            this.$sentry.captureMessage(e);
          }
        }
      } else {
        try {
          const res = await this.$store.dispatch(
            "control/removeItemAction",
            id
          );
          console.log(res);
          if (res && res.status == 204) {
            this.$message.success("Новости успешно удаленно");
            this.fetchData();
          }
        } catch (e) {
          this.$sentry.captureMessage(e);
        }
      }
      this.selectedCards = [];
      this.cardsIsEmpty = true;
    },
    editControl(item) {
      this.$router.push({
        name: "staffs-detail",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.search-error {
  position: absolute;
  left: 0;
  top: 100%;
  color: red;
}
</style>
